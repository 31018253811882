/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";

import { bootstrapApp, ErrorScreen } from "ui-kit";

import App from "./App";
import config from "./config";
import en from "./i18n/en.json";
import newKeys from "./i18n/new-keys.json";

const { DEBUG } = config;

bootstrapApp({
  App,
  ErrorScreen,
  messages: { en, newKeys },
  querySelector: "#root",
  DEBUG,
});
