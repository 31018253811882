import type { RouteType } from "ui-kit";
import { lazy, NotFoundPage, React, Redirect } from "ui-kit";

import AppTopBarLayout from "../layouts/AppTopBar";

const getApplicationRoutes = (routesConfig: RouteType[]): RouteType[] => {
  const definedRoutes = routesConfig.map((route) => ({
    ...route,
    exact: route?.exact || true,
    layout: route?.layout || AppTopBarLayout,
  }));

  return [
    {
      path: "/",
      exact: true,
      component: () => <Redirect to="/invite-error" />,
    },
    ...definedRoutes,
    {
      path: "*",
      component: () => <NotFoundPage />,
      layout: AppTopBarLayout,
    },
  ];
};

const applicationRoutes: RouteType[] = [
  {
    path: "/invite-error",
    component: lazy(() => import("../pages/invite-error")),
    layout: AppTopBarLayout,
  },
  {
    path: "/accept-invite/:code?",
    component: lazy(() => import("../pages/accept-invite")),
    exact: true,
    layout: AppTopBarLayout,
  },
  {
    path: "/auth-error",
    component: lazy(() => import("../pages/auth-error")),
    exact: true,
    layout: AppTopBarLayout,
  },
];

export default getApplicationRoutes(applicationRoutes);
